@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.navbar {
  flex: 0 0 90px;
  max-height: 90px;
  background: $app-color;
  position: relative;
  display: flex;
  z-index: 1;
  overflow-y: visible;
  position: fixed;
  width: 100%;
  &::after {
    content: "";
    height: 85%;
    width: 100%;
    background: $primary-color;
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .menu {
    width: 0;
    height: 85%;
    flex: 1;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    padding-top: 25px;
    .menuItem {
      color: white;
      font-size: 20px;
    //  font-weight: 600;
      cursor: pointer;
      transition: all 400ms ease;
   
      .threedots{
        display: block;
      }
      .active{
        font-weight: 900;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .logoWrap {
    display: flex;
    z-index: 1;
    height: 160px;
    width: fit-content;
    margin: auto;
    max-height: 160px;
    padding: 20px 40px;
    background: white;
    border: 0.5px solid #E5E5E5;
    cursor: pointer;
    border-radius: 25px;
  }
  .threedots{
    display: none;
  }
  @include mdportrait {
    flex: 0 0 70px;
    max-height: 70px;
    .menu {
      padding: 0;
      .menuItem {
        display: none;
      }
     
    }
    .logoWrap {
      height: 120px;
      max-height: 120px;
      padding: 20px;
      justify-content: center;
      align-items: center;
      border: 0.5px solid #E5E5E5;
      img {
        height: 80%;
      }
    }
    .threedots{
      display: flex;
    z-index: 1;
    // height: 160px;
    width: fit-content;
    margin: auto;
    max-height: 160px;
    margin-top: 20px;
    // padding: 20px 40px;
    // background: white;
    }
  }
}::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none !important;
}

.fullbgm{
  background: #212224;
  height: 100vh;
}
