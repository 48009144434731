@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.shopbg {
    background: url("../../static/images/bgs/shopbg.png");
    height: 0;
    flex: 1;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    /* -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%; */
}

.theshop-text {
    color: white;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    quotes: 0;
    width: 700px;
}

.shop-title {
    font-size: 100px;
    font-weight: 900;
    line-height: 100px;
}

.shop-tagline {
  
    margin-top: 25px;
    font-weight: 700;
font-size: 28px;
line-height: 45px;
/* or 161% */

display: flex;
align-items: center;

width: 527px;

opacity: 0.5;
}

.col-sm-61 {
    width: 50%;
}

.pre-regbtn {
    padding: 20px 50px;
    background: #fab544;
    // border: 1px solid white;
    border-radius: 20px;
    font-size: 22px;
    margin-top: 50px;
    display: flex;
    font-style: italic;
    @include btn-animate(white);
}

.shopmbbg {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../static/images/bgs/shopmbpg.png");
    height: 0;
    flex: 1;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.shop-mb-title {
   
    display: table-cell;
    vertical-align: middle;
    height: 100vh;
    width: 1%;
    text-align: center;
}
.shop-mb-title-text{
    font-weight: 900;
    font-size: 65px;
    color: #FFFFFF;
    line-height: 60px;
}
.shop-mb-desc {
    font-weight: 700;
    font-size: 22px;
    margin-top: 20px;
    color: #FFFFFF;
}
.pre-reg-mb-btn{
    margin-top: 50px;
    background: #fab544;
    color: white;
    // border: 2.5px solid #FFFFFF;
    border-radius: 20px;
    padding: 15px 45px;
    font-weight: 800;
    font-size: 20px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
    border: none;
}
.fire-icon{
    margin-right: 10px;
    margin-top: 3px;
}