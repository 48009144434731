.shanti-img{
    height: 100vh;
    flex: 1 1;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.theimage-place{ 
    background-image: url("../../static/images/logo/ShantiPeopleImg.png");
    background-size: cover;
    /* flex: 0 0 38.7%; */
    flex: 0.904;
    /* width: calc(50% - 12vw); */
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    /* background-position: center; */
}
.maindisp-img{
    display: flex;
    flex: 1 1;
    height: 0;
}
.right-event-cont{
    margin: 150px 10px 20px 120px;
    width: 50%;
}
.maintitle-event{
    font-weight: 700;
    font-size: 35px;
    color: #222128;
}
.date-right{
    float: right;
    margin-top: -46px;
}
.event-month{
    font-weight: 500;
    font-size: 15px;
    color: #222128;
}
.event-date{
    font-weight: 700;
    font-size: 25px;
    color: #222128;
    margin-top: -15px;
    margin-left: 5px;
}
.hashes{
    display: flex;
}
.hashtags{
    font-weight: 700;
    font-size: 15px;
    color: #222128;
    border: 0.5px solid #E5E5E5;
    border-radius: 10px;
    padding: 7px 20px;
    cursor: pointer;
}
/* .nav-item{
    border: 1px solid;
} */
/* .nav-link:hover ,.active{
    background: black;
    color: white;
}
.nav>li>a:focus, .nav>li>a:hover, .nav>li>a:active{
    background: black;
    cursor: pointer;
    color: white;
}
.tab-content{
    border: 1px solid;
}
.nav-link{
    border: 1px solid;
}
a{
    color: black;
}
.nav-tabs>li>a{
    
} */

.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: transparent;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    /* padding: 14px 16px; */
    font-size: 15px;
    color: #464B4E;
    padding: 15px 47px;
    border-right: 0.5px solid #E7E7E7;
    border-left: 0.5px solid #E7E7E7;
    width: fit-content;
  }
  .start-tab{
      border-left: 0px !important;
  }
  .end-tab{
      border-right: 0px !important;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #212224;
    color: white;
    font-weight: 700;
    font-size: 15px;
    border-right: 0.5px solid #212224;
    border-left: 0.5px solid #212224;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #212224;
    color: white;
    font-weight: 700;
    font-size: 15px;
    border-right: 0.5px solid #212224;
    border-left: 0.5px solid #212224;

  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    /* padding: 6px 12px; */
    border: 1px solid #ccc;
    border-top: none;
    height: 50vh;
    overflow: scroll;
    border-bottom: none;
    width: 100%;
  }
  .tablinks{
      background: none;
  }
  .event-content-style{
     margin: 50px;
  }
  .abt-title{
    font-weight: 700;
    font-size: 20px;
    color: #212224;
  }
  .about-desc{
    font-weight: 400;
    font-size: 15px;
    line-height: 35px;
    color: #464B4E;    
  }
  .about-points{
    font-weight: 400;
    font-size: 15px;
    line-height: 35px;
    color: #464B4E;
    
  }
  .event-venue-style{
      margin: 50px;
  }
  .venue-data{
      display: flex;
  }
  .data-text{
      margin-left: 20px;
  }
  .venue-club{
    font-weight: 800;
    font-size: 25px;   
    color: #212224;
  }
  .venue-city{
    font-weight: 400;
    font-size: 12px; 
    align-items: center; 
    margin-top: -10px;
  }
  .venue-desc{
      margin-top: 30px;
      font-weight: 300;
      font-size: 15px;
      line-height: 35px;
      color: #464B4E;
  }
  .box-buttons{
      float: right;
      margin-top: -33px;
      border-radius: 5px;
      color: #464B4E;
      font-weight: 700;
      font-size: 12px;
      padding: 3px 20px;
      cursor: pointer;
      border: 0.5px solid #E5E5E5;
  }
  .share-btn{
      margin-right: 10px;
  }
  .venue-details{
    font-weight: 300;
    font-size: 15px;
     color: #464B4E;
  }
  .venue-footer{
      position: absolute;
      bottom: 0;
      background: black;
      width: 61.3%;
      margin-left: -121px;
  }
  .footer-boxes{
      display: flex;
      margin-left: 110px;
  }
  .box-data{
      color: white;
      margin-left: 20px;
      font-size: 20px;
      border: 1px solid;
      padding: 8px 50px;
      margin-top: 20px;
      border-radius: 10px;
      cursor: pointer;
      font-weight: 600;
      text-align: center;
  }

  /* @media screen and (min-width : 769px) and (max-width: 850px) {
    .theimage-place{
        flex: 0 0 37.5%;
    }
  }
  @media screen and (min-width : 851px) and (max-width: 920px) {
    .theimage-place{
        flex: 0 0 38.7%;
    }
  }
  @media screen and (min-width : 921px) and (max-width: 992px) {
    .theimage-place{
        flex: 0 0 39.7%;
    }
  } */
  @media screen and (min-width : 2000px) {
    .box-data{
        width: 20%;
    }
    .theimage-place{
        flex: 0.93
    }
  }

  @media screen and (max-width : 768px) {
      .img-mobile{
          width: 100%;
      }
      .mobile-text{
          margin: 20px;
          font-weight: 700;
          font-size: 17px;
          color: #222128;
          width: 200px;
      }
      .mob-date{
          text-align: end;
          margin-top: -65px;
          margin-right: 20px;
      }
      .mob-month{
        font-weight: 500;
        font-size: 15px;
        color: #222128;        
      }
      .date-disp{
        font-weight: 700;
        font-size: 17px;
        color: #222128;
        margin-top: -15px;
        margin-right: 8px;
      }
      .mobile-hashes{
          display: flex;
          margin-left: 20px;
      }
      .mobile-tags{
        font-weight: 700;
        font-size: 10px;
        color: #222128;
        border: 0.5px solid #E5E5E5;
        border-radius: 7px;
        padding: 5px 20px;
      }
      .arrow-drop{
        border: 0.5px solid #E5E5E5;
        padding-top: 10px;
        padding-bottom: 20px;
        cursor: pointer;
      }
      .about-text-sty{
        font-weight: 700;
        font-size: 20px;
        color: #212224;
        margin-top: 20px;
        margin-left: 20px;
      }
      .arrow-mobile{
          float: right;
          margin-right: 20px;
          margin-top: -32px;
      }
      .event-footer{
        background: #212224;
        height: 80px;
        display: flex;
        overflow: scroll;
      }
      .bottom-mobile-text{
        color: white;
        margin-top: 16px;
        margin-left: 16px;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        margin-bottom: 16px;
        padding: 22px 32px;  
        padding-top: 11px;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
      }
  }
  @media screen and (min-height : 718px) {
    .event-footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
  }