.navbarbgm {
  background: white;
  /* height: 100vh; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
}

.navbarbgm::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.close-icon {
  flex: 0 0 70px;
  max-height: 70px;
  position: relative;
  width: 0;
  height: 85%;
  flex: 1;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 400ms ease;
}

.the-fontclr {
  color: #212224;
  font-weight: 400;
  font-size: 25px;
  align-items: center;
  text-align: center;
  margin-top: 4vh;
  cursor: pointer;
  overflow-x: auto;
  font-style: italic;
}

.nav-active {
  font-weight: 900 !important;
}

.close-icon-sty {
  margin: 25px;
}

.in-logo-sty {
  text-align: center;
  margin-top: -10px;
  width: 75px;
  margin-left: auto;
  margin-right: auto;
}

.thefont-opac {
  opacity: 0.25;
}

.the-about-nav {
  display: flex;
  border: 0.5px solid #E5E5E5;
  margin-top: 86px;
 
}

.the-about-text {
  /* padding-left: 60px;
    font-weight: 400;
    font-size: 15px;
    align-items: center;
    text-align: center;
    color: #212224;
    padding-right: 160px;
    margin-top: 15px;
    padding-top: 11px;
    padding-bottom: 14px;
    font-style: italic; */
    
    color: black;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all 400ms ease;
    font-style: italic;
    width: 0;
    height: 85%;
    flex: 1 1;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    padding-top: 25px;

}
.themenu{
  margin-top: 100px;
}
.color-nav{
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  transition: all 400ms ease;
  font-style: italic;
  color: #212224 !important;
}
.the-nav-detail{
  margin-top: 89px;
  margin-left: -39%;
  border: 0.5px solid #E5E5E5;
  border-left: none;
  border-right: none;
  background: white;
}

.isCurrent {
  font-weight: 900 !important;
}

.isCurrent a {
  color: #5F6163;
  font-weight: 800;
}
li{
  list-style-type: none;
  text-decoration: none;
  /* margin-top: 5px; */
  margin-bottom: 15px;
}
a{
    text-decoration: none !important;
    list-style-type: none;
    color: white;
    margin-top: -15px;
}

