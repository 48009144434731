.regpage-mobile {
    flex: 0 0 70px;
    max-height: 70px;
    /* position: relative; */
    /* width: 0; */
    height: 100%;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 400ms ease;
    overflow-y: hidden;
}


.regbg {
    background: linear-gradient(0deg, rgba(33, 34, 36, 0.65), rgba(0, 0, 0, 0.3)), url("../../static//images/bgs/registerbgm.png");
    height: 360vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.card {
    background: white;
    width: 700px;
    text-align: center;
    border-radius: 25px;
    padding-top: 50px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 658px;
}

.modal-invite {
    border: none;
    text-align: start;
    font-weight: 700;
    font-size: 25px;
    margin-top: 130px;
    border-bottom: 1px solid #E5E5E5;
    width: 75%;
    padding-bottom: 30px;
}

.modal-invite:focus {
    outline: none;
}

.modal-invite::placeholder {
    opacity: 0.25;
}

.modal-footer-btn {
    width: 100%;
    height: 100px;
    margin-top: 170px;
    border-radius: 0px 0px 25px 25px;
    border: none;
    font-weight: 800;
    font-size: 30px;
    color: #FFFFFF;
    background: #212224;
    cursor: pointer;
}

.modal-footer-btn1 {
    width: 50%;
    height: 100px;
    margin-top: 170px;
    border-radius: 0px 0px 25px 0px;
    border: none;
    font-weight: 800;
    font-size: 30px;
    color: #FFFFFF;
    background: #212224;
    cursor: pointer;
}

.modal-footer-btn2 {
    width: 50%;
    height: 100px;
    margin-top: 170px;
    border-radius: 0px 0px 0px 25px;
    border: none;
    font-weight: 800;
    font-size: 30px;
    color: #212224;
    background: white;
    border: 0.5px solid #E5E5E5;
    cursor: pointer;
}

.the-navdisp {
    display: none;
}

.number-code {
    border: none;
    text-align: start;
    font-weight: 700;
    font-size: 25px;
    margin-top: 130px;
    border-bottom: 1px solid #E5E5E5;
    width: 75%;
    padding-bottom: 34px;
    margin-right: 18px;
    color: #E5E5E5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.invite-name {
    font-weight: 700;
    font-size: 20px;
    color: #212224;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    background: white;
    padding: 15px 40px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.25;
}

.invitee-title {
    font-weight: 300;
    font-size: 18px;
    color: #212224;
}

.invitee-img {
    margin-top: 32px;
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    border-radius: 100px;
}

.invitee-name {
    font-weight: 700;
    font-size: 30px;
    color: #212224;
}

.invitee-email {
    font-weight: 300;
    font-size: 18px;
    color: #212224;
}

.invitee-buttons {
    margin-top: -93px;
}

.firstinvite-btn {
    margin-top: -80px;
}

.input-group {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.email-code-field {
    width: 78px;
    height: 99px;
    margin-left: 10px;
    border: 0.5px solid #E5E5E5;
}

input[type="tel"] {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: black;
}

.email-code-title {
    font-weight: 700;
    font-size: 22px;
    color: #212224;
    margin-top: 50px;
}

.resend-code-sty {
    font-weight: 700;
    font-size: 19px;
    color: #212224;
    opacity: 0.25;
}

.resend-code-text {
    font-weight: 700;
    font-size: 19px;
    color: #212224;
}

.resend-code-sty,
.resend-code-text {
    margin-top: 165px;
    cursor: pointer;
}

.dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-left: -20px;
}
.eye-display{
    width: 20px;
    height: 20px;
}
.down-buttons {
    display: flex;
    border: none;
    margin-left: 15px;
    margin-bottom: 10px;
    position: absolute;
    bottom: 0;
    width: 95%;
}

@media screen and (max-width : 600px) {
    .card {
        width: 250px;
    }

    .img-size {
        width: 200px;
    }

    .modal-invite {
        margin-top: 80px;
    }

    .modal-invite::placeholder {
        font-size: 15px;
    }

    .modal-footer-btn,
    .modal-footer-btn1,
    .modal-footer-btn2 {
        margin-top: 100px;
        height: 70px;
        font-size: 20px;
    }

    .the-navdisp {
        display: block;
    }

    .regbg {
        display: none;
    }

    .mobile-input {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 90%;
        text-align: center;
    }

    .mobile-input-field {
        width: 79%;
        border-bottom: 1px solid #E5E5E5 !important;
        padding-bottom: 30px;
        border: none;
        /* padding: 30px; */
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 20px;
        color: #212224;
    }

    .mobile-input-field::placeholder {
        opacity: 0.25;
    }

    .mobile-next {
        background: #FAB544;
        margin-left: 10px;
        border: none;
        width: 75%;
        border: 1px solid #FAB544;
        border-radius: 10px;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
    }

    /* .down-buttons {
        display: flex;
        border: none;
        margin-left: 15px;
        margin-bottom: 10px;
        position: absolute;
        bottom: 0;
        width: 95%;
    } */

    .invite-name {
        font-size: 15px;
        padding: 5px 20px;
    }

    .invitee-buttons {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .modal-footer-btn2 {
        border: 0.5px solid #E5E5E5;
        border-radius: 2px;
        color: #FAB544
    }

    .modal-footer-btn1 {
        border: 0.5px solid #E5E5E5;
        border-radius: 2px;
        background: #FAB544
    }

    .dot {
        margin-left: 20px;
    }

    .email-code-field {
        width: 35px;
        height: 40px;
        margin-left: 10px;
        border: 0.5px solid #E5E5E5;
    }

    .resend-code-sty,
    .resend-code-text {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        opacity: 1;
    }

    .number-code {
        font-size: 20px;
    }


}