/* .bets-dash-sider {
    background-color: #334480!important;
    height: 100%;
    overflow: scroll;
} */
.ant-layout{
    display: flex;
    flex: auto;
}
.ant-layout.ant-layout-has-sider{
    flex-direction: row;
}
.ant-layout-sider-children{
    border: 0.5px solid #E7E7E7;
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
}
.ant-layout-sider-trigger{
    border: 0.5px solid #E7E7E7;
}
.logo{
    cursor: pointer;
    border-bottom: 3px solid #ffffff;
    margin-top: 50px;
    margin-left: 30px;
}
.the-name{
    font-weight: 700;
    font-size: 22px;
    margin-top: 10px;
   color: #212224;
}
.the-email{
    font-weight: 400;
    font-size: 13px;
    color: #212224;
    margin-top: -10px;
}
.group{
    padding-top: 30px;
    padding-left: 30px;
 
}
.true1 , .true1:hover{
    padding: 0px;
    margin-left: 30px;
    margin-top: 30px;
    background: white !important;
}
.group:hover{
    background: lightgray;
}
.menu-itm{
    color: #212224;
    opacity: 0.25;
}
.active{
    color: #212224;
    opacity: 1;
}
.sider-contents{
    margin-top: 30px;
   /* margin-top: 50px;
   margin-left: 30px; */
}
.side-down-arrow{
    margin-left: 200px;
    margin-top: -70px;
}
.side-names{
    font-weight: 700;
    font-size: 16px;
    color: #212224;
    margin-bottom: 10px;
}
.lock-icon{
    position: absolute;
    bottom: 0;
    opacity: 0.9;
    border: 0.5px solid #E7E7E7;
    text-align: center;
    width: 299px;
    padding: 20px;
}
.lock-img{
    margin-top: -10px;
}
.lock-text{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #212224;
    margin-left: 10px;
    cursor: pointer;
}
.collapse-sty{
    margin-left: 259px;
    margin-top: -112px;
}
.expand-sty{
    margin-left: 39px;
    margin-top: -74px;
}
.true{
    width: 50px !important;
    height: 50px !important;
    margin-left: -20px;
}
.true:hover{
    padding: 0px;
}
.sider-collapse-icon{
    margin-left: -12px;
    margin-top: 3vw;
}



 .tooltip-sty{
    margin-left: 70px;
    margin-top: -36px;
    opacity: 0.9;
    border: 0.5px solid #E7E7E7;
    font-weight: 600;
    font-size: 15px;
    color: #08152D;
    box-sizing: border-box;
    padding: 6px 0px;
    width: 100px;
    text-align: center;
    opacity: 1 !important;
    display: none;
}
.sider-collapse-icon:hover  + .tooltip-sty{
    display: block;
}


.user-icon{   
    width: 80px;
    height: 80px;
    border-radius: 60px;
}
