.mobile-next1 {
    background: #FAB544;
    /* margin-left: 10px; */
    margin-right: 15px;
    border: none;
    width: 100%;
    border: 1px solid #FAB544;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    padding: 20px;
}

.only-logo {
    margin-top: 200px;
    width: 450px;
    height: 150px;
    animation-name: loader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes loader {
    0% {
        width: 450px;
        height: 150px;
    }

    50% {
        width: 600px;
        height: 200px;
        margin-top: 180px;
    }

    100% {
        width: 450px;
        height: 150px;
        margin-top: 200px;
    }
}

@media screen and (max-width : 600px) {
    .dispcard{
        text-align: center;
    }
    .only-logo {
        margin-top: 200px;
        width: 200px;
        height: 100px;
        animation-name: loader;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    @keyframes loader {
        0% {
            width: 200px;
            height: 100px;
        }

        50% {
            width: 350px;
            height: 150px;
            margin-top: 180px;
        }

        100% {
            width: 200px;
            height: 100px;
            margin-top: 200px;
        }
    }
}