@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .centerText {
    text-align: center;
  }

  .videoWrap {
    height: 0;
    flex: 1;
    position: relative;
    background-image: url("../../static/images/bgs/landingbg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .videoOverlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000000b0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      color: white;
      padding: 0 60px;

      .title {
        // width: 60%;
        font-family: "Montserrat";
        font-style: italic;
        font-weight: 800;
        font-size: 105px;
        line-height: 100px;
        /* or 95% */
        margin-top: 11.5rem;

        display: flex;
        align-items: center;

        color: #ffffff;
      }

      .desc {
        font-size: 30px;
        color: $app-color;
        font-weight: bold;
        font-style: italic;
      }

      .btns {
        flex: 0 0 60px;
        max-height: 60px;
        display: flex;

        .btnHow,
        .btnGetStarted {
          min-width: 200px;
          height: 120%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: fit-content;
          border: 2px solid white;
          padding: 0 50px;
          border-radius: 20px;
          margin: 30px 15px 0 15px;
          font-weight: bold;
          font-style: italic;
          font-size: 22px;
          @include btn-animate(white);

          svg {
            height: 50%;
            margin-right: 10px;
          }
        }
        @include mdportrait {
          margin-top: 40vw;
          padding: 0px 45px;
          font-size: 22px;
        }

        .btnGetStarted {
          background: $app-color;
          border-color: $app-color;
        }
      }
    }
  }

  .footerList {
    flex: 0 0 30%;
    max-height: 30%;
    display: flex;
    padding: 20px 5%;
    overflow-x: auto;
    justify-content: space-between;

    .cityCard {
      flex: 0 0 160px;
      max-width: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      margin-right: 3%;
      transition: all 400ms ease;

      &:last-of-type {
        margin-right: 0;
      }

      img {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        border: 1px solid white;
        object-fit: cover;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }

      span {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 600;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @include mdportrait {
    .videoWrap {
      .videoOverlay {
        .title {
          width: 90%;
          font-size: 30px;
          white-space: unset;
        }

        .desc {
          width: 90%;
          font-size: 20px;
          text-align: center;
        }

        .btns {
          flex: 0 0 50px;
          max-height: 50px;

          .btnHow,
          .btnGetStarted {
            min-width: unset;
            padding: 0 15px;
            border-radius: 25px;
            margin: 30px 5px 0 5px;
            font-size: 15px;
          }
        }
      }
    }

    .footerList {
      flex: 0 0 25%;
      max-height: 25%;
      padding: 20px 5%;

      .cityCard {
        flex: 0 0 100px;
        max-width: 100px;
        margin-right: 10%;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          height: 100px;
          width: 100px;
        }

        span {
          font-size: 15px;
        }
      }
    }
  }
}
