@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.nftsbg {
  // background: url("../../static/images/bgs/nftsbg.png");
  height: 0;
  flex: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  .right {
    width: 50%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .outerinerbackground {
      width: 100%;

      height: 30%;
 
    }
    .innerbackground {
      height: 70%;
   
      width: 100%;
      display: flex;
      align-items: center;
      .leftsidesection {
        width: 50%;
        height: 100%;
      }
      .rightsideSection {
        width: 50%;
        height: 100%;
        .top{
            height: 50%;
            width: 100%;
        }
        .bottom{
            height: 50%;
            width: 100%;
        }
      }
    }
  }
}

.nfts-card {
  width: 50%;
  height: 100%;
  margin: 0;
  float: right;
  background:#212224;
  margin-top: 200px;
  padding: 60px;
  margin-top: 177px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  align-items: baseline;
  padding-top: 0px;
}

.nfts-to-remember {
  font-weight: 900;
  font-size: 100px;
  line-height: 100px;
  color: #ffffff;
  width: 700px;
}

.nfts-tag {
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 35px;
  line-height: 43px;
  color: #ffffff;
}

.nftbtn {
  padding: 20px 50px;
  background: #fab544;
  // border: 1px solid white;
  border-radius: 20px;
  font-weight: 800;
  font-size: 22px;
  margin-top: 25px;
  display: flex;
  font-style: italic;
  color: white;
  @include btn-animate(white);
}

.nftmbbg {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../static/images/bgs/mbnftbg.png");
  height: 0;
  flex: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.nft-mb-title {
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  width: 1%;
  text-align: center;
}
.nft-mb-title-text {
  font-weight: 900;
  font-size: 50px;
  color: #ffffff;
  line-height: 45px;
  font-style: italic;
}
.nft-mb-desc {
  font-weight: 700;
  font-size: 22px;
  margin-top: 20px;
  color: #ffffff;
  font-style: italic;
}
