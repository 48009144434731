@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.event-section-one {
    /* height: 374px; */
    /* background: #E5E5E5; */
    display: flex;
}

.event-card {
    background: white;
    margin-top: 13px;
    height: 348px;
    width: 25%;
    border-radius: 10px;
    margin-bottom: 13px;
}

.main-event-card {
    background: white;
    margin-top: 13px;
    height: 348px;
    width: 50%;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.border-left {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.border-right {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.event-section-two,
.event-section-four {
    margin-left: 60px;
    margin-top: 100px;
}

.title-one {
    font-weight: 800;
    font-size: 30px;
    color: #212224;
    font-style: italic;
    /* margin-left: 50px;
    margin-bottom: 30px; */
    margin: 100px 0px 30px 60px
}

.title-two,
.title-four {
    font-weight: 800;
    font-size: 30px;
    color: #212224;
    margin-bottom: 40px;
    font-style: italic;
}

.performance-cards,
.hub-cards {
    display: flex;
}

.hub-cards {
    margin-right: 35px;
}

.right-margin {
    margin-right: 10px;
}

.vertical-card {
    width: 25%;
    height: 425px;
    background: #FFFFFF;
    border-radius: 15px;
    margin-right: 55px;
}



.vertical-card1 {
    width: 25%;
    height: 425px;
    background: #FFFFFF;
    border-radius: 15px;
    margin-right: 50px;
    border: 0.5px solid #E5E5E5;
    text-align: center;
}

.event-section-three {
    padding-left: 60px;
    /* background: #212224; */
    margin-top: 250px;
}

.title-three {
    font-weight: 800;
    font-size: 30px;
    color: #212224;
    padding-top: 45px;
    margin-bottom: 40px;
    font-style: italic;
}

.img-cards {
    margin-bottom: 50px;
    background-color: rgba(34, 33, 40, 0.57);
    border-radius: 5px;
    position: relative;
    text-align: center;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../static/images/bgs/pubsimg.jpg");
    width: 20%;
    height: 180px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 25px;
    cursor: pointer;
}

.img-cards1 {
    margin-bottom: 50px;
    background-color: rgba(34, 33, 40, 0.57);
    border-radius: 5px;
    position: relative;
    text-align: center;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../static/images/bgs/nightclubimg.jpg");
    width: 20%;
    height: 180px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 25px;
    cursor: pointer;
}

.img-cards2 {
    margin-bottom: 60px;
    background-color: rgba(34, 33, 40, 0.57);
    border-radius: 5px;
    position: relative;
    text-align: center;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../static/images/bgs/concerts.jpg");
    width: 20%;
    height: 180px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 25px;
    cursor: pointer;
}

.img-cards3 {
    margin-bottom: 50px;
    background-color: rgba(34, 33, 40, 0.57);
    border-radius: 5px;
    position: relative;
    text-align: center;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../static/images/bgs/restaurantimg.jpg");
    width: 20%;
    height: 180px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 25px;
    cursor: pointer;
}

.img-cards4 {
    margin-bottom: 50px;
    background-color: rgba(34, 33, 40, 0.57);
    border-radius: 5px;
    position: relative;
    text-align: center;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../static/images/bgs/driveinnimg.jpg");
    width: 20%;
    height: 180px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 25px;
    cursor: pointer;
}

.img-cards:hover,
.img-cards1:hover,
.img-cards2:hover,
.img-cards3:hover,
.img-cards4:hover {
    // box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
    transition: box-shadow .3s ease-in-out;
    transform: scale(1.1);
    transition: all 0.5s ease;
    // transform-origin: 0;
}


.img-disp {
    border-radius: 5px;
    background: rgba(34, 33, 40, 0.57);
}

.hub-text {
    color: white;
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-style: italic;

}

.event-section-five {
    margin-top: 200px;
    background: #222128;
    padding-left: 46px;
}

.title-five {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
    padding-top: 20px;
}

.event-card-sec5 {
    height: 252px;
    /* background: #FFFFFF; */
    border-right: 0.5px solid #E7E7E7;
    /* border-radius: 10px; */
    width: 30%;
    margin-bottom: 53px;
    margin-right: 35px;
    font-style: italic;
}

.the-rightborder {
    border: none !important;
}

.stream-cards {
    display: flex;
}

.hr-tag {
    border: 1px solid;
    width: 95%;
    margin-left: -15px;
}

.footer-text {
    color: white;
    font-weight: 300;
    font-size: 15px;
    margin-left: -15px;
    font-weight: 700;
    margin-bottom: 20px;
    font-style: italic;
    padding-bottom: 30px;
    text-align: left;
}

.footer-text1 {
    color: white;
    font-weight: 300;
    font-size: 15px;
    margin-left: -15px;
    font-weight: 700;
    margin-bottom: 20px;
    font-style: italic;
    padding-bottom: 30px;
    text-align: end;
    margin-right: 50px;
    margin-top: -72px;
    cursor: pointer;
    float: right;
    background: none;
    border: none;
}

.footer-text1:hover{
    transform: scale(1.05);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.the-main-carousel {
    margin: 0px 10px;
}

.carousel .thumbs {
    display: none;
}

.the-img-performer {
    width: -webkit-fill-available;
    cursor: pointer;
}

.the-img-performer:hover {
    transition: box-shadow .3s ease-in-out;
    transform: scale(1.05);
    transition: all 0.5s ease;
}

.vertical-card:hover {
    transition: box-shadow .3s ease-in-out;
    transform: scale(1.05);
    transition: all 0.5s ease;
    cursor: pointer;
}

.vertical-card1:hover {
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
    transition: box-shadow .3s ease-in-out;
    transform: scale(1.05);
    transition: all 0.5s ease;
    cursor: pointer;
}




.venue-card {
    margin-top: 75px;
    /* width: 100%; */
}

.the-text-btm {
    font-weight: 700;
    font-size: 20px;
    color: white;
}
.the-footer-text:hover{
    transform: scale(1.1);
}

@media screen and (min-width : 2000px) {
    .the-img-performer {
        height: 577px;
        width: 425px;
    }
}

@media screen and (max-width : 600px) {

    .event-section-two,
    .event-section-four {
        margin-left: 0;
        margin-top: 20px;
    }

    .event-section-three {
        padding-left: 0px;
        padding-top: 1px;
        text-align: center;
    }

    .main-event-card,
    .event-card {
        height: 193px;
    }

    .title-one,
    .title-two {
        font-weight: 700;
        font-size: 22px;
        color: #212224;
        text-align: center;
        margin: 15px 0px;
        display: block;
        margin-bottom: 25px;
    }

    .title-one {
        margin-bottom: 30px;
        font-weight: 800;
    }



    .title-two {
        margin-bottom: -5px;
        margin-top: 40px;
        font-weight: 800;
    }

    .title-three {
        margin-top: 20px;
        margin-bottom: 25px;
        font-size: 22px;
        padding-top: 0px;
    }

    .event-section-five {
        margin-top: 50px;
    }

    .title-five {
        padding: 40px 0px;
    }

    .performance-cards,
    .hub-cards {
        flex: 0 0 30%;
        max-height: 30%;
        display: flex;
        padding: 20px 5%;
        overflow-x: auto;
        justify-content: space-between;
    }

    .vertical-card,
    .vertical-card1 {
        flex: 0 0 185px;
        max-width: 185px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        // margin-right: 3%;
        transition: all 400ms ease;
    }

    .vertical-card {
        margin-right: -5%;
    }

    .vertical-card1 {
        margin-right: 3%;
    }

    .img-cards,
    .img-cards1,
    .img-cards2,
    .img-cards3,
    .img-cards4,
    .img-cards5 {
        flex: 0 0 265px;
        max-width: 265px;
        height: 145px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        margin-right: 3%;
        transition: all 400ms ease;
    }

    .event-section-five {
        text-align: center;
        margin-left: 0;
        padding-left: 0;
    }

    .stream-cards {
        display: block;
    }

    .event-card-sec5 {
        height: 150px;
        /* background: #FFFFFF; */
        border-bottom: 0.5px solid #E7E7E7;
        border-right: none;
        /* border-radius: 10px; */
        width: 80%;
        margin-bottom: 30px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-style: italic;
    }

    .hr-tag {
        width: 80%;
        margin-left: auto;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 0.25px solid #E7E7E7;
    }

    .footer-text {
        margin-left: auto;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 12px;
        padding-bottom: 20px;
        padding-top: 15px;
    }

    .event-section-three {
        margin-top: 20px;
    }

    .venue-card {
        width: 50%;
        margin-top: 0px;
    }

    .app-images {
        width: 100px;
    }

    .title-one-main {

        font-size: 25px;
        text-align: center;
        margin-left: 0;
        margin-top: 50px;

    }

    .theround-image {
        width: 100px;
        height: 100px;
    }

    .logo-text {
        font-size: 15px;
    }

    .the-img-performer {
        width: 150px;
    }

    .performance-cards {
        margin-left: -20px;
    }

    .right-margin {
        margin-left: 0px;
    }

    .cityCard {
        flex: 0 0 120px;
        max-width: 120px;
    }

    .hub-cards {
        margin-right: 0px;
    }

    .title-four {

        text-align: center;
        font-size: 22px;
        margin-bottom: 25px;
        margin-top: -10px;

    }

    .places-data {
        padding-top: 20px;
    }

    .title-five {
        display: none;
    }

    .footer-text {
        text-align: center;
    }

    .the-down-main {
        display: flex;
    }

    .the-event-foot-text {
        color: white;
        font-style: italic;
        width: 33%;
        margin-top: 20px;
        font-weight: 700;
        font-size: 12px;
    }
}