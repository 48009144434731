@import "../../static/scss/colors";
@import "../../static/scss/mixin";
.playStore {
  display: flex;
  align-items: flex-start;
  margin-top: 4.7rem;
  img {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .line {
    width: 1px;
    background-color: white;
    height: 62px;
    margin-left: 50px;
    margin-right: 50px;
  }
}

.online-broder-line {
  width: 2px;
  height: 75%;
  transform: rotate(20deg);
  background-color: #fff;
}
.ant-modal-header {
  display: none;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-close-x {
  display: none;
}

.ant-modal-content {
  border-radius: 25px;
}

.the-logodisp {
  text-align: center;
  padding-top: 55px;
}

.ant-modal-body {
  padding: 0px;
}

.modal-email {
  border: none;
  text-align: start;
  font-weight: 700;
  font-size: 25px;
  margin-top: 110px;
  border-bottom: 1px solid #e5e5e5;
  width: 75%;
  padding-bottom: 30px;
}

.modal-email:focus {
  outline: none;
}

.modal-email::placeholder {
  opacity: 0.25;
}

.events-page-display {
  height: 0%;
  flex: 0;
}

.app-footer {
  display: flex;
  padding: 20px 5%;
  overflow-x: auto;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 75%;
  bottom: 0;
  align-items: center;
}

.app-logos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-right: 3%;
  transition: all 400ms ease;
  width: 33%;
  @include btn-animate1(white);
}

// .app-logos::before {
//     content: "";
//     background: white;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     bottom: 0;
//     margin: auto;
//     position: absolute;
//     left: -220%;
//     transform-origin: center center;
//     transform: rotateZ(115deg);
// }

// .app-logos:hover::before {
//     transition: all 0.6s ease;
//     left: 10%;
// }
// .app-logos:hover::after {
//     display: none;
// }

.vl {
  border: 1px solid #e7e7e7;
  transform: rotate(-155deg);
}

.theround-image {
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  height: 160px;
  object-fit: cover;
  width: 160px;
}

.logo-text {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #212224;
  font-weight: 800;
  letter-spacing: 0.03em;
  font-style: italic;
}

.logos-data {
  display: flex;
  padding: 20px 5%;
  overflow-x: auto;
  justify-content: space-between;
}

.cityCard {
  flex: 0 0 160px;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-right: 3%;
  transition: all 400ms ease;
  opacity: 0.5;
}

.cityCard:hover {
  transition: box-shadow 0.3s ease-in-out;
  transform: scale(1.05);
  transition: all 0.5s ease;
  opacity: 1;
}

.places-data {
  /* background: #222128; */
  padding-bottom: 50px;
  padding-top: 50px;
}

.title-one-main {
  font-weight: 800;
  font-size: 45px;
  color: #212224;
  /* margin-left: 50px; */
  font-style: italic;
  margin: 100px 0px 0px 50px;
}

.card-active {
  opacity: 1 !important;
}
.the-title-mb {
  width: 90%;
  font-size: 30px;
  white-space: unset;
  font-weight: 800;
  text-align: center;
  font-style: italic;
}
.btn-start {
  min-width: 200px;
  height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  // border: 2px solid white;
  // padding: 0 50px;
  border-radius: 20px;
  // margin: 30px 15px 0 15px;
  font-weight: bold;
  font-style: italic;
  font-size: 18px;
  background: #fab544;
  margin-top: 10vh;
  border: none;
  @include btn-animate(white);
}

@media screen and (max-width: 281px) {
  .btn-start {
    width: 150px;
    font-size: 15px;
  }
  .the-title-mb {
    font-size: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .online-broder-line {
    display: none !important;
  }
}
