#the-about {
  height: 0;
  flex: 1 1;
  width: 100%;
}
.the-about-data {
  // color: black;
  // font-weight: 700;
  // font-size: 20px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // margin: 0;
  // font-style: italic;
  padding-left: 60px;
  padding-top: 89px;

  height: 100%;
  display: flex;
  align-items: center;
  background-color: #212224;
  // margin-top: 40vh;
  // margin-bottom: 25vh;
  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right {
    width: 50%;
    width: 50%;
    display: flex;
    /* align-items: center; */
    height: 100%;

    align-items: center;
    justify-content: end;
    padding-right: 60px;
  }
}

.the-about-tag {
  font-weight: 800;
  font-size: 55px;
  color: #fff;
}

.the-about-rem {
  font-weight: 700;
  font-size: 35px;
  color: #fab544;

  line-height: 30px;
}

.the-about-desc {
  font-weight: 700;
  color: #ffffff;
  opacity: 0.5;
  width: 613px;
  line-height: 55px;
  margin-top: 44px;
  font-size: 22px;
  line-height: 35px;
}

.left-data {
  text-align: center;
  margin-left: 50px;
  text-transform: capitalize;
  font-style: italic;
}

.the-width-adj {
  margin-top: 280px;
}

.about-founder-1 {
  font-weight: 800;
  font-size: 45px;
  line-height: 55px;
  display: flex;
  align-items: center;
  color: #212224;
  margin-top: 70px;
}

.co-founder {
  font-weight: 700;
  font-size: 25px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #fab544;
}

.founder-1-desc {
  font-weight: 300;
  font-size: 18px;
  line-height: 55px;
  display: flex;
  color: #212224;
  // width: 600px;
  text-align: justify;
  margin-bottom: 20vw;
  margin-right: 20vw;
}

.img-founder {
  margin-left: 50px;
}

.founder-2-desc {
  font-weight: 300;
  font-size: 18px;
  line-height: 55px;
  display: flex;
  color: #212224;
  text-align: justify;
  margin-bottom: 20vw;
  margin-right: 20vw;
}
.right-img-founder {
  float: right;
  margin-right: 50px;
}

@media screen and (max-width: 768px) {

  .the-about-data {
    width: fit-content;
    padding-left: 7%;
    padding-right: 7%;
    text-align: center;
    .right{
        display: none;
        
    }
    .left{
        width: 100%;
    }
  }
  .the-about-tag {
    font-size: 30px;
  }

  .the-about-rem {
    font-weight: 700;
    font-size: 20px;
  }

  .the-about-desc {
    width: 100%;
    font-size: 14px;
    line-height: 25px;
  }

  .the-data {
    text-align: center;
    margin-top: 150px;
  }

  .founder-sty {
    font-weight: 800;
    font-size: 35px;
    color: #212224;
    font-style: italic;
  }

  .founder-text {
    font-weight: 700;
    font-size: 20px;
    color: #fab544;
    font-style: italic;
  }

  .founder-img {
    margin-top: 50px;
  }

  .founder-desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 45px;
    color: #212224;
    width: 85%;
    margin: 20px auto;
    text-transform: capitalize;
  }

  .the-margin {
    margin-top: 10vh;
  }

  .bottom-text {
    margin-top: 7px;
    margin-left: 16px;
    border-radius: 10px;
    margin-bottom: 16px;
    padding: 22px 32px;
    padding-top: 11px;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    font-style: italic;
  }

  .isCurrent {
    text-decoration: underline !important;
    text-underline-position: under;
    text-decoration-color: #fab544 !important;
    color: white !important;
  }
  .about-footer {
    background: #212224;
    height: 60px;
    display: flex;
    overflow: scroll;
  }
  .sticky {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  // .isCurrent a{
  //     text-decoration: underline;
  //     text-underline-position: under;
  //     text-decoration-color: #FAB544;
  // }
}

// @media screen and (min-height : 718px) {
//     .event-footer{
//         position: absolute;
//         bottom: 0;
//         width: 100%;
//     }
//   }
